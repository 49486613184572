import React from 'react';
import { css } from '@emotion/core';
import Image from '../components/Image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { container, title } from '../styles';

const flexWrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 37rem) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const imageWrapper = css`
    flex: 1 1 30%;
    width: 70%;
    max-width: 210px;
    margin-bottom: 1rem;

    @media (min-width: 37rem) {
        width: auto;
        max-width: 320px;
    }
`;

const textWrapper = css`
    flex: 1 1 70%;
    text-align: left;
    line-height: 1.8;
    font-family: "DM San's", sans-serif;

    @media (min-width: 37rem) {
        margin-left: 2rem;
    }

    @media (min-width: 50rem) {
        margin-left: 4rem;
        max-width: 36rem;
    }
`;

const AboutPage = () => {
    return (
        <Layout>
            <SEO title="About" />
            <div css={container}>
                <h1 css={title}>About</h1>
                <div css={flexWrapper}>
                    <div css={imageWrapper}>
                        <Image name="self" />
                    </div>
                    <div css={textWrapper}>
                        <p>
                            Cupcake ipsum dolor sit amet halvah cake dragée cake. Muffin halvah lollipop I love chupa
                            chups I love dessert powder cheesecake. Soufflé jelly beans sweet liquorice I love jelly-o
                            sugar plum cotton candy biscuit. Powder chocolate wafer lollipop sweet. Marshmallow
                            croissant caramels cotton candy I love chupa chups. Chocolate cake cheesecake oat cake I
                            love jelly chocolate wafer donut. Halvah soufflé caramels macaroon toffee. Marshmallow
                            powder biscuit.
                        </p>
                        <p>
                            I love chocolate cake tootsie roll liquorice bear claw liquorice jelly jujubes. Powder candy
                            I love I love cake. Macaroon tootsie roll I love chocolate. Wafer chocolate gummies apple
                            pie halvah I love tootsie roll. Lemon drops liquorice candy canes. Jelly I love sugar plum.
                            Bonbon I love I love biscuit I love toffee cake sugar plum brownie. Sweet roll I love
                            marshmallow I love fruitcake I love danish soufflé.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AboutPage;
